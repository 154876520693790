import React, { useState, useEffect } from "react";
import {
  Form,
  Wizard,
  SpaceBetween,
  Button,
  Container,
  Header,
  FormField,
  Input,
  Link,
  ColumnLayout,
  Box,
  BreadcrumbGroup,
  Textarea,
  Badge,
  RadioGroup,
  FileUpload,
  Flashbar,
  Select
} from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import { uploadData } from "aws-amplify/storage";
import { post, put } from "aws-amplify/api";
import { apiName, demosPath } from "../../tool/api";
import "./Submit_Demo.css";

const successMes = "Created success";
const errorMess = "Error! An error occurred. Please try again.";

function KeyValueGroup(props) {
  return (
    <>
      {props.items.map((item, index) => (
        <ColumnLayout columns={3} key={index}>
          <Input
            value={item.name}
            onChange={(e) => {
              var newReferUrl = [...props.items];
              newReferUrl[index].name = e.detail.value;
              props.onChange(newReferUrl);
            }}
          />
          <Input
            value={item.url}
            onChange={(e) => {
              var newReferUrl = [...props.items];
              newReferUrl[index].url = e.detail.value;
              props.onChange(newReferUrl);
            }}
          />
          <Button
            onClick={() => {
              var newReferUrl = [...props.items];
              newReferUrl.splice(index, 1);
              props.onChange(newReferUrl);
            }}
          >
            Remove
          </Button>
        </ColumnLayout>
      ))}
    </>
  );
}
export default function Submit_demo() {
  const [demoInfor, setDemoInfor] = useState({
    name: "",
    description: "",
    email: "hunggia@amazon.com",
    duration: "",
    price: "",
  });
  const [serviceUsed, setServiceUsed] = useState([]);
  const [currentService, setCurrentService] = useState("");
  const [industry, setIndustry] = useState([]);
  const [currentIndustry, setCurrentIndustry] = useState("");
  const [technology, setTechnology] = useState([]);
  const [currentTechnology, setCurrentTechnology] = useState("");
  const [demoVideo, setDemoVideo] = useState([]);
  const [srcCode, setSrcCode] = useState([]);
  const [architecture, setArchitecture] = useState([]);;
  const [coverImg, setCoverImg] = useState([]);
  const [slide, setSlide] = useState([]);
  const [diagram, setDiagram] = useState([]);
  const [guideDoc, setGuideDoc] = useState([]);
  const [author, setAuthor] = useState([]);
  const [version, setVersion] = useState([]);
  const [uiSample, setUISample] = useState([]);
  const [referUrl, setReferUrl] = useState([
    {
      name: "",
      url: "",
    },
  ]);
  const [videoMode, setVideMode] = useState("s3");
  const [tag, setTag] = useState({
    label: "Visible",
    value: "Visible",
  });
  const [flashItem, setFlashItem] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [youtubeVideo, setYoutubeVideo] = useState("");
  const [score, setScore] = useState([]);
  const navigate = useNavigate();

  const NavigateToLaunches = () => {
    navigate("/myDemos");
  };
  const onCancel = () => {
    navigate("/myDemos");
  };

  useEffect(() => {
    document.title = "Submit Demo";
  }, []);

  const convertNameToID = (name) => {
    let str_name = name.trim().toLowerCase();
    let id = str_name.replace(/[^\w\s]/gi, '').replace(/ /g, "-");
    return id;
  };

  const checkRequire = () => {
    if (demoInfor.name && (demoVideo.length > 0 || youtubeVideo)) {
      return true;
    }
    return false;
  };

  const submitDemos = async (e) => {
    e.preventDefault();
    setIsSubmit(true);

    if (!checkRequire()) {
      setIsSubmit(false);
      return;
    }

    const randomCode = Math.floor(Math.random() * 1000000);
    var videoPath, archPath, srcCodePath, slidePath, guidePath, dgrPath, coverPath;
    var uiSamplePath = [];

    try {
      // upload video
      if (demoVideo.length > 0) {
        videoPath = await uploadData({
          path: `public/video/${randomCode}-${demoVideo[0].name}`,
          data: demoVideo[0],
        }).result;
      }

      // upload architech image
      if (coverImg.length > 0) {
        coverPath = await uploadData({
          path: `public/architecture/${randomCode}-${coverImg[0].name}`,
          data: coverImg[0],
        }).result;
      }

      // upload architech image
      if (architecture.length > 0) {
        archPath = await uploadData({
          path: `public/architecture/${randomCode}-${architecture[0].name}`,
          data: architecture[0],
        }).result;
      }

      // upload documents
      if (srcCode.length > 0) {
        srcCodePath = await uploadData({
          path: `public/srcCode/${randomCode}-${srcCode[0].name}`,
          data: srcCode[0],
        }).result;
      }

      if (slide.length > 0) {
        slidePath = await uploadData({
          path: `public/slide/${randomCode}-${slide[0].name}`,
          data: slide[0],
        }).result;
      }

      if (guideDoc.length > 0) {
        guidePath = await uploadData({
          path: `public/guiDoc/${randomCode}-${guideDoc[0].name}`,
          data: guideDoc[0],
        }).result;
      }

      if (diagram.length > 0) {
        dgrPath = await uploadData({
          path: `public/diagram/${randomCode}-${diagram[0].name}`,
          data: diagram[0],
        }).result;
      }

      if (uiSample.length > 0) {
        for(var i = 0; i < uiSample.length; i++) {
          const tmpPth = await uploadData({
            path: `public/uiSample/${randomCode}-${uiSample[i].name}`,
            data: uiSample[i],
          }).result;
          uiSamplePath.push(tmpPth.path);
        }
      }

      
      // call api post demo data
      const data = {
        id: convertNameToID(demoInfor.name),
        name: demoInfor.name,
        description: demoInfor.description,
        email: demoInfor.email,
        duration: demoInfor.duration,
        price: demoInfor.price,
        authors: author.split("; "),
        serviceUsed: serviceUsed,
        industry: industry,
        technology: technology,
        youtube: youtubeVideo,
        demoVideo: videoPath ? videoPath.path : "",
        architecture: archPath ? archPath.path : "",
        coverImg: coverPath ? coverPath.path : "",
        srcCode: srcCodePath ? srcCodePath.path : "",
        slide: slidePath ? slidePath.path : "",
        guideDoc: guidePath ? guidePath.path : "",
        diagram: dgrPath ? dgrPath.path : "",
        referUrl: referUrl,
        uiSample: uiSamplePath,
        lastUpdate: new Date().toISOString(),
        status: "Enabled",
        started: false,
        tag: tag.value,
        impactScore: Number.parseInt(score),
      };

      const restOperation = post({
        apiName: apiName,
        path: demosPath,
        options: {
          body: data,
        },
      });
      const { body } = await restOperation.response;
      const response = await body.json();
      console.log(response);

      setIsSubmit(true);
      setFlashItem([
        {
          type: "success",
          content: successMes,
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: () => setFlashItem([]),
          id: "error_message",
        },
      ]);
    } catch (error) {
      console.log(error);
      setIsSubmit(false);
      setFlashItem([
        {
          type: "error",
          content: errorMess,
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: () => setFlashItem([]),
          id: "error_message",
        },
      ]);
    } finally {
      setIsSubmit(false);
    }
  };

  const deleteTag = (index, kind) => {
    switch (kind) {
      case "serviceUsed":
        setServiceUsed(serviceUsed.filter((_, i) => i !== index));
        break;
      case "industry":
        setIndustry(industry.filter((_, i) => i !== index));
        break;
      case "technology":
        setTechnology(technology.filter((_, i) => i !== index));
        break;
      default:
        break;
    }
  };

  const renderTag = (kind) => {
    var list = [];
    switch (kind) {
      case "serviceUsed":
        list = [...serviceUsed];
        break;
      case "industry":
        list = [...industry];
        break;
      case "technology":
        list = [...technology];
        break;
      default:
        break;
    }

    return (
      <SpaceBetween size="xs" direction="horizontal">
        {list.map((item, index) => (
          <div>
            <Badge color="blue">{item}</Badge>
            <Button
              iconName="close"
              variant="icon"
              onClick={() => deleteTag(index, kind)}
            />
          </div>
        ))}
      </SpaceBetween>
    );
  };

  const renderReferUrl = () => {
    return (
      <>
        {referUrl.map((item, index) => (
          <div className="requirement-item">
            <li className="requirement-item-haft" key={index}>
              {item}
            </li>
            <Button
              iconName="close"
              variant="icon"
              // onClick={() => deleteTag(index, kind)}
            />
          </div>
        ))}
      </>
    );
  };

  const renderDemoResources = () => {
    const reference = (
      <>
        <ColumnLayout columns={2} variant="text-grid">
          <FormField
            label={
              <span>
                Slide <i>- optional</i>{" "}
              </span>
            }
            description="Related documents"
          >
            <FileUpload
              onChange={async ({ detail }) => {
                setSlide(detail.value);
              }}
              value={slide}
              i18nStrings={{
                uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                dropzoneText: (e) =>
                  e ? "Drop files to upload" : "Drop file to upload",
                removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                limitShowFewer: "Show fewer files",
                limitShowMore: "Show more files",
                errorIconAriaLabel: "Error",
              }}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={3}
              constraintText=".pdf, .doc, .docx, .pptx"
              accept=".pdf, .doc, .docx, .pptx"
            />
          </FormField>
          <FormField
            label={
              <span>
                Diagram <i>- optional</i>{" "}
              </span>
            }
            description="Draw.io format"
          >
            <FileUpload
              onChange={async ({ detail }) => {
                setDiagram(detail.value);
              }}
              value={diagram}
              i18nStrings={{
                uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                dropzoneText: (e) =>
                  e ? "Drop files to upload" : "Drop file to upload",
                removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                limitShowFewer: "Show fewer files",
                limitShowMore: "Show more files",
                errorIconAriaLabel: "Error",
              }}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={3}
              constraintText=".drawio"
              accept=".drawio"
            />
          </FormField>
          <FormField
            label={
              <span>
                Implement documents <i>- optional</i>{" "}
              </span>
            }
            description="Guiding demo implement"
          >
            <FileUpload
              onChange={async ({ detail }) => {
                setGuideDoc(detail.value);
              }}
              value={guideDoc}
              i18nStrings={{
                uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                dropzoneText: (e) =>
                  e ? "Drop files to upload" : "Drop file to upload",
                removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                limitShowFewer: "Show fewer files",
                limitShowMore: "Show more files",
                errorIconAriaLabel: "Error",
              }}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={3}
              constraintText=".md"
              accept=".md"
            />
          </FormField>
          <FormField
            label={
              <span>
                Source code <i>- optional</i>{" "}
              </span>
            }
            description="Source code of demo"
          >
            <FileUpload
              onChange={async ({ detail }) => {
                setSrcCode(detail.value);
              }}
              value={srcCode}
              i18nStrings={{
                uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                dropzoneText: (e) =>
                  e ? "Drop files to upload" : "Drop file to upload",
                removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                limitShowFewer: "Show fewer files",
                limitShowMore: "Show more files",
                errorIconAriaLabel: "Error",
              }}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={3}
              constraintText=".zip, .rar"
              accept=".zip, .rar"
            />
          </FormField>
          <FormField
            label={<span>Architecture Cover</span>}
            description="Architecture Images with cover size (3/2)"
          >
            <FileUpload
              onChange={async ({ detail }) => {
                setCoverImg(detail.value);
              }}
              value={coverImg}
              i18nStrings={{
                uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                dropzoneText: (e) =>
                  e ? "Drop files to upload" : "Drop file to upload",
                removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                limitShowFewer: "Show fewer files",
                limitShowMore: "Show more files",
                errorIconAriaLabel: "Error",
              }}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={3}
              constraintText=".png, .jpg, .jpeg"
              accept=".png, .jpg, .jpeg"
            />
          </FormField>

          <FormField
            label={<span>Architecture Standard</span>}
            description="Architecture Images with standard size (5/3)"
          >
            <FileUpload
              onChange={async ({ detail }) => {
                setArchitecture(detail.value);
              }}
              value={architecture}
              i18nStrings={{
                uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                dropzoneText: (e) =>
                  e ? "Drop files to upload" : "Drop file to upload",
                removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                limitShowFewer: "Show fewer files",
                limitShowMore: "Show more files",
                errorIconAriaLabel: "Error",
              }}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={3}
              constraintText=".png, .jpg, .jpeg"
              accept=".png, .jpg, .jpeg"
            />
          </FormField>
        </ColumnLayout>

        <FormField
            label={<span>Demo UI</span>}
            description="Demo UI Images"
          >
            <FileUpload
              onChange={async ({ detail }) => {
                setUISample(detail.value);
              }}
              value={uiSample}
              i18nStrings={{
                uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                dropzoneText: (e) =>
                  e ? "Drop files to upload" : "Drop file to upload",
                removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                limitShowFewer: "Show fewer files",
                limitShowMore: "Show more files",
                errorIconAriaLabel: "Error",
              }}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={3}
              multiple
              constraintText=".png, .jpg, .jpeg"
              accept=".png, .jpg, .jpeg"
            />
          </FormField>

        <FormField label="Document URL" description="">
          <ColumnLayout columns={3}>
            <i>Name</i>
            <i>URL</i>
          </ColumnLayout>
          <div id="url-group">
            <KeyValueGroup items={referUrl} onChange={setReferUrl} />
          </div>
        </FormField>
        <Button
          variant="primary"
          onClick={() => {
            setReferUrl([...referUrl, { name: "", url: "" }]);
          }}
        >
          Add URL
        </Button>
        <ColumnLayout columns={1} variant="text-grid">
          {renderReferUrl}
        </ColumnLayout>
      </>
    );

    return (
      <SpaceBetween direction="vertical" size="m">
        <RadioGroup
          onChange={({ detail }) => setVideMode(detail.value)}
          value={videoMode}
          items={[
            {
              value: "s3",
              label: "Upload video",
              description: "Upload your own video",
            },
            { value: "youtube", label: "Youtube video" },
          ]}
        />
        {videoMode === "s3" ? (
          <FormField label="Demo Video" description="">
            <FileUpload
              onChange={async ({ detail }) => {
                setDemoVideo(detail.value);
                // const video = await this.setLectureLength(detail.value[0]);
                // this.setState({ lectureVideoLength: video.duration });
                // if (checked) {
                //   this.setState({ checked: false });
                //   this.resetLectureVideo();
                // }
              }}
              value={demoVideo}
              i18nStrings={{
                uploadButtonText: (e) => (e ? "Choose files" : "Choose file"),
                dropzoneText: (e) =>
                  e ? "Drop files to upload" : "Drop file to upload",
                removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                limitShowFewer: "Show fewer files",
                limitShowMore: "Show more files",
                errorIconAriaLabel: "Error",
              }}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={3}
              constraintText=".mov, .mp4, .gif"
              accept=".mov,.mp4,.gif"
            />
          </FormField>
        ) : (
          <FormField
            label="Youtube Video ID"
            description="For example: zbiNEyZRhDU"
          >
            <Input
              value={youtubeVideo}
              onChange={async (event) => {
                setYoutubeVideo(event.detail.value);
                // this.getYouVideoDuration(event.detail.value);
              }}
            />
          </FormField>
        )}
        {reference}
      </SpaceBetween>
    );
  };

  return (
    <div className="submitDemo">
      <SpaceBetween size="l">
        <BreadcrumbGroup
          items={[
            { text: "Home", href: "/" },
            { text: "My Demos", href: "#/myDemos" },
            {
              text: "Submit Demo",
              href: "#/myDemos/createDemo",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />
        <Flashbar items={flashItem} />
        <form onSubmit={(e) => submitDemos(e)}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  formAction="none"
                  variant="link"
                  onClick={NavigateToLaunches}
                >
                  Cancel
                </Button>
                <Button variant="primary" loading={isSubmit}>
                  Submit
                </Button>
              </SpaceBetween>
            }
            header={<Header variant="h1">Submit your Demo</Header>}
          >
            <SpaceBetween direction="vertical" size="l">
              <Container header={<Header variant="h2">Overview</Header>}>
                <SpaceBetween
                  direction="vertical"
                  size="s"
                  variant="borderless"
                >
                  <FormField label="Demo name">
                    <Input
                      value={demoInfor.name}
                      onChange={(event) =>
                        setDemoInfor({ ...demoInfor, name: event.detail.value })
                      }
                    />
                  </FormField>
                  <FormField label="Description">
                    <Textarea
                      value={demoInfor.description}
                      onChange={(event) =>
                        setDemoInfor({
                          ...demoInfor,
                          description: event.detail.value,
                        })
                      }
                    />
                  </FormField>
                  <ColumnLayout columns={2}>
                    <FormField label="Contact email">
                      <Input
                        value={demoInfor.email}
                        onChange={(event) =>
                          setDemoInfor({
                            ...demoInfor,
                            email: event.detail.value,
                          })
                        }
                      />
                    </FormField>
                    <FormField label="Estimated Launch Time">
                      <Input
                        value={demoInfor.duration}
                        onChange={(event) =>
                          setDemoInfor({
                            ...demoInfor,
                            duration: event.detail.value,
                          })
                        }
                      />
                    </FormField>
                    <FormField
                      label="Demo cost"
                      description="Estimated cost for signal user"
                    >
                      <Input
                        value={demoInfor.price}
                        onChange={(event) =>
                          setDemoInfor({
                            ...demoInfor,
                            price: event.detail.value,
                          })
                        }
                      />
                    </FormField>
                    <FormField
                      label="Author(s)"
                      description="Each author is separated by ;"
                    >
                      <Input
                        value={author}
                        onChange={(event) =>
                          setAuthor(event.detail.value)
                        }
                      />
                    </FormField>
                    <FormField label="Tag" description="Mark tag for demo">
                      <Select
                        selectedOption={tag}
                        onChange={({ detail }) =>
                          setTag(detail.selectedOption)
                        }
                        options={[
                          { label: "Featured", value: "Featured" },
                          { label: "Visible", value: "Visible" },
                          { label: "Progress", value: "Progress" },
                        ]}
                      />
                    </FormField>
                    <FormField
                      label="Impact score"
                      description="Impact level of demos (1-100)"
                    >
                      <Input
                        value={score}
                        onChange={(event) =>
                          setScore(event.detail.value)
                        }
                      />
                    </FormField>
                  </ColumnLayout>
                </SpaceBetween>
              </Container>
              <ColumnLayout columns={3}>
                <Container header={<Header variant="h2">Service Used</Header>}>
                  <SpaceBetween
                    direction="vertical"
                    size="s"
                    variant="borderless"
                  >
                    <FormField label="Service name">
                      <Input
                        value={currentService}
                        onChange={(event) =>
                          setCurrentService(event.detail.value)
                        }
                        onKeyUp={(event) => {
                          if (event.detail.keyCode === 13) {
                            setServiceUsed([...serviceUsed, currentService]);
                            setCurrentService("");
                          }
                        }}
                      />
                    </FormField>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setServiceUsed([...serviceUsed, currentService]);
                        setCurrentService("");
                      }}
                    >
                      Add
                    </Button>
                    <>{renderTag("serviceUsed")}</>
                  </SpaceBetween>
                </Container>

                <Container header={<Header variant="h2">Industry</Header>}>
                  <SpaceBetween
                    direction="vertical"
                    size="s"
                    variant="borderless"
                  >
                    <FormField label="Industry name">
                      <Input
                        value={currentIndustry}
                        onChange={(event) =>
                          setCurrentIndustry(event.detail.value)
                        }
                        onKeyUp={(event) => {
                          if (event.detail.keyCode === 13) {
                            setIndustry([...industry, currentIndustry]);
                            setCurrentIndustry("");
                          }
                        }}
                      />
                    </FormField>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setIndustry([...industry, currentIndustry]);
                        setCurrentIndustry("");
                      }}
                    >
                      Add
                    </Button>
                    <>{renderTag("industry")}</>
                  </SpaceBetween>
                </Container>

                <Container header={<Header variant="h2">Technology</Header>}>
                  <SpaceBetween
                    direction="vertical"
                    size="s"
                    variant="borderless"
                  >
                    <FormField label="Technology name">
                      <Input
                        value={currentTechnology}
                        onChange={(event) =>
                          setCurrentTechnology(event.detail.value)
                        }
                        onKeyUp={(event) => {
                          if (event.detail.keyCode === 13) {
                            setTechnology([...technology, currentTechnology]);
                            setCurrentTechnology("");
                          }
                        }}
                      />
                    </FormField>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setTechnology([...technology, currentTechnology]);
                        setCurrentTechnology("");
                      }}
                    >
                      Add
                    </Button>
                    <>{renderTag("technology")}</>
                  </SpaceBetween>
                </Container>
              </ColumnLayout>

              <Container header={<Header variant="h2">Demo Resources</Header>}>
                <SpaceBetween direction="vertical" size="s">
                  {renderDemoResources()}
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          </Form>
        </form>
      </SpaceBetween>
    </div>
  );
}











