import * as React from "react";
import { useEffect, useState } from "react";
import {
  Badge,
  BreadcrumbGroup,
  Cards,
  SpaceBetween,
  Button,
  TextFilter,
  Header,
  Pagination,
  CollectionPreferences,
  Grid,
  Link,
  Box,
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
// import demoData from "./demoData";
import { useNavigate, useLocation } from "react-router-dom";
import { EmptyState } from "../../tool/tool"
import "./Browse_Demo.css";
import {
  apiName,
  demosPath,
  myLaunchesPath,
  displayedPath,
  browseDemosPath,
} from "../../tool/api";
import { get, put } from "aws-amplify/api";
import { getUrl } from "aws-amplify/storage";

export default () => {
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();
  const NavigateToLaunch = (item) => {
    navigate(`/demoDetail/${item.id}`, {
      state: item,
    });
  };
  const [allDemos, setAllDemos] = useState([]);
  const [loading, setLoading] = useState(false);

  const [preferences, setPreferences] = useState({
    pageSize: 9,
    visibleContent: [
      "name",
      "image",
      "description",
      "keyawsservices",
      "industry",
      "technology",
    ],
  });
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(allDemos, {
    filtering: {
      empty: <EmptyState title="No demo" />,
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  useEffect(() => {
    getAllDemos();
  }, []);

  const getAllDemos = async () => {
    setLoading(true);
    try {
      const restOperation = get({
        apiName: apiName,
        path: demosPath + displayedPath,
      });
      const { body } = await restOperation.response;
      const json = await body.json();
      console.log(json);
      // setAllDemos(json);

      let newAllDemos = [...json];
      for (var i = 0; i < json.length; i++) {
        var imgPth = "";
        const item = json[i];
        if (item.coverImg) {
          imgPth = item.coverImg;
        } else {
          imgPth = item.architecture;
        }

        try {
          const getUrlResult = await getUrl({
            path: imgPth,
            options: {
              validateObjectExistence: true,
            },
          });
          newAllDemos[i].coverImgUrl = getUrlResult.url.toString();
        } catch (error) {
          console.error("Error getting URL:", error);
        }
        if (i === json.length - 1) {
          setAllDemos(newAllDemos);
          setLoading(false);
        }
      }
      // json.map(async (item, index) => {
      //   handleGetUrl(imgPth)
      //     .then((url) => {
      //       newAllDemos[index].coverImgUrl = url;
      //       if (index === json.length - 1) {
      //         setAllDemos(newAllDemos);
      //         setLoading(false);
      //         console.log(newAllDemos);
      //       }
      //     })
      //     .catch((error) => {
      //       console.error(error);
      //       setLoading(false);
      //     });

      // });

      //console.log(setAllDemos);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleGetUrl = async (path) => {
    try {
      const getUrlResult = await getUrl({
        path,
        options: {
          validateObjectExistence: true,
        },
      });
      // return getUrlResult;
      return getUrlResult.url.toString();
    } catch (error) {
      console.error("Error getting URL:", error);
      throw error;
    }
  };

  return (
    <div className="browseDemo">
      <SpaceBetween size="xl">
        <BreadcrumbGroup
          items={[
            { text: "Home", href: "#" },
            {
              text: "Browse",
              href: "/browseDemos",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />

        <Cards
          {...collectionProps}
          // renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
          //     `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
          // }
          onSelectionChange={({ detail }) =>
            setSelectedItems(detail?.selectedItems ?? [])
          }
          //variant="full-page"
          selectedItems={selectedItems}
          ariaLabels={{
            itemSelectionLabel: (e, i) => `select ${i.name}`,
            selectionGroupLabel: "Item selection",
            itemSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${
                selectedItems.length === 1 ? "item" : "items"
              } selected`,
            itemSelectionLabel: ({ selectedItems }, item) => item.nameDemo,
          }}
          cardDefinition={{
            header: (item) => (
              <Box margin={{ top: "l", bottom: "xl" }}>
                <Grid
                  gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                  disableGutters
                >
                  <Link
                    variant="primary"
                    header="h1"
                    fontSize="heading-l"
                    onClick={() => NavigateToLaunch(item)}
                  >
                    {item.name}
                  </Link>
                </Grid>
              </Box>
            ),
            sections: [
              {
                id: "image",
                content: (item) => (
                  <img
                    // className="img-cover"
                    style={{ width: "100%", aspectRatio: "3/2" }}
                    src={
                      item.hasOwnProperty("coverImgUrl") ? item.coverImgUrl : ""
                    }
                    alt="placeholder"
                  />
                ),
              },
              {
                id: "description",
                header: "Description",
                content: (item) => item.description,
              },
              {
                id: "keyawsservices",
                header: "Key AWS Services",
                content: (item) => (
                  <SpaceBetween size="xxs" direction="horizontal">
                    {item.serviceUsed?.map((i, index) => {
                      if (index < 4) {
                        return <Badge color="blue">{i}</Badge>;
                      }
                      if (index == 4) {
                        return (
                          <Badge color="blue">
                            +{item.serviceUsed.length - index}
                          </Badge>
                        );
                      }
                    })}
                  </SpaceBetween>
                ),
                width: 100,
              },
              {
                id: "industry",
                header: "Industry",
                content: (item) => (
                  <SpaceBetween size="xxs" direction="horizontal">
                    {item.industry.map((i) => (
                      <Badge color="blue">{i}</Badge>
                    ))}
                  </SpaceBetween>
                ),
                width: 100,
              },
              {
                id: "technology",
                header: "Technology",
                content: (item) => (
                  <SpaceBetween size="xxs" direction="horizontal">
                    {item.technology.map((i) => (
                      <Badge color="blue">{i}</Badge>
                    ))}
                  </SpaceBetween>
                ),
                width: 100,
              },
            ],
          }}
          cardsPerRow={[{ cards: 1 }, { cards: 3, minWidth: 500 }]}
          visibleColumns={preferences.visibleContent}
          items={items}
          loadingText="Loading resources"
          loading={loading}
          trackBy="nameDemo"
          // visibleSections={[
          //   "image",
          //   "description",
          //   "keyawsservices",
          //   "industry",
          //   "technology",
          // ]}
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No resources</b>
                <Button>Create resource</Button>
              </SpaceBetween>
            </Box>
          }
          filter={
            <div className="input-container">
              <TextFilter
                {...filterProps}
                filteringPlaceholder="Find resources"
              />
            </div>
          }
          header={
            <Header
              counter={
                selectedItems?.length
                  ? `(${selectedItems.length}/` + `${allDemos.length})`
                  : "(" + allDemos.length + ")"
              }
            >
              All Demos
            </Header>
          }
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <CollectionPreferences
              title="Preferences"
              preferences={preferences}
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              setPreferences={setPreferences}
              pageSizePreference={{
                title: "Page size",
                options: [
                  { value: 9, label: "9 demos" },
                  { value: 15, label: "15 demos" },
                  { value: 21, label: "21 demos" },
                ],
              }}
              visibleContentPreference={{
                title: "Select visible content",
                options: [
                  {
                    label: "Main distribution properties",
                    options: [
                      {
                        id: "name",
                        label: "Demo Name",
                        editable: false,
                      },
                      { id: "updatedAt", label: "Last Updated" },
                      { id: "state", label: "State" },
                      { id: "author", label: "Author" },
                    ],
                  },
                ],
              }}
              onConfirm={({ detail }) => setPreferences(detail)}
            />
          }
        />
      </SpaceBetween>
    </div>
  );
};

// return (
//     <div className="browseDemo">
//         <SpaceBetween size="xl">
//             <BreadcrumbGroup
//                 items={[
//                     { text: "Home", href: "/" },
//                     {
//                         text: "Browse",
//                     },
//                 ]}
//                 ariaLabel="Breadcrumbs"
//             />

//             <Cards
//                 // renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
//                 //     `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
//                 // }
//                 onSelectionChange={({ detail }) =>
//                     setSelectedItems(detail?.selectedItems ?? [])
//                 }
//                 //variant="full-page"
//                 selectedItems={selectedItems}
//                 ariaLabels={{
//                     itemSelectionLabel: (e, i) => `select ${i.name}`,
//                     selectionGroupLabel: "Item selection",
//                     itemSelectionLabel: ({ selectedItems }) =>
//                         `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
//                         } selected`,
//                     itemSelectionLabel: ({ selectedItems }, item) => item.nameDemo,
//                 }}
//                 cardDefinition={{
//                     header: (item) => (
//                         <Box margin={{ top: "l", bottom: "xl" }}>
//                             <Link
//                                 variant="primary"
//                                 header="h1"
//                                 fontSize="heading-l"
//                                 onClick={() => NavigateToLaunch(item)}
//                             >
//                                 {item.name}
//                             </Link>
//                         </Box>
//                     ),
//                     sections: [
//                         {
//                             id: "image",
//                             content: (item) => (
//                                 <img
//                                     style={{ width: "100%", aspectRatio: "3/1.8" }}
//                                     src={item.urlImg}
//                                     alt="placeholder"
//                                 />
//                             ),
//                         },
//                         {
//                             id: "description",
//                             header: "Description",
//                             content: (item) => item.description,
//                         },
//                         {
//                             id: "keyawsservices",
//                             header: "Key AWS Services",
//                             content: (item) => (
//                                 <SpaceBetween size="xxs" direction="horizontal">
//                                     {item.keyawsservices?.map((i, index) => {
//                                         if (index < 4) {
//                                             return <Badge color="blue">{i}</Badge>;
//                                         }
//                                         if (index == 4) {
//                                             return (
//                                                 <Badge color="blue">
//                                                     +{item.keyawsservices.length - index}
//                                                 </Badge>
//                                             );
//                                         }
//                                     })}
//                                 </SpaceBetween>
//                             ),
//                             width: 100,
//                         },
//                         {
//                             id: "industry",
//                             header: "Industry",
//                             content: (item) => (
//                                 <SpaceBetween size="xxs" direction="horizontal">
//                                     {item.industry.map((i) => (
//                                         <Badge color="blue">{i}</Badge>
//                                     ))}
//                                 </SpaceBetween>
//                             ),
//                             width: 100,
//                         },
//                         {
//                             id: "technology",
//                             header: "Technology",
//                             content: (item) => (
//                                 <SpaceBetween size="xxs" direction="horizontal">
//                                     {item.technology.map((i) => (
//                                         <Badge color="blue">{i}</Badge>
//                                     ))}
//                                 </SpaceBetween>
//                             ),
//                             width: 100,
//                         },
//                     ],
//                 }}
//                 cardsPerRow={[{ cards: 1 }, { cards: 3, minWidth: 500 }]}
//                 items={allDemos}
//                 // items={[
//                 //     {
//                 //         name: "Item 1",
//                 //         keyawsservices: "First",
//                 //         description: "This is the first item",
//                 //         industry: "1A",
//                 //     },
//                 //     {
//                 //         name: "Item 1",
//                 //         keyawsservices: "First",
//                 //         description: "This is the first item",
//                 //         industry: "1A",
//                 //     }
//                 // ]}
//                 loadingText="Loading resources"
//                 //selectionType="multi"
//                 loading={loading}
//                 trackBy="nameDemo"
//                 visibleSections={[
//                     "image",
//                     "description",
//                     "keyawsservices",
//                     "industry",
//                     "technology",
//                 ]}
//                 empty={
//                     <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
//                         <SpaceBetween size="m">
//                             <b>No resources</b>
//                             <Button>Create resource</Button>
//                         </SpaceBetween>
//                     </Box>
//                 }
//                 filter={<TextFilter filteringPlaceholder="Find resources" />}
//                 header={
//                     <Header
//                         counter={
//                             selectedItems?.length
//                                 ? `(${selectedItems.length}/` + `${allDemos.length})`
//                                 : "(" + allDemos.length + ")"
//                         }
//                     >
//                         All Demos
//                     </Header>
//                 }
//                 pagination={<Pagination currentPageIndex={1} pagesCount={2} />}
//                 preferences={
//                     <CollectionPreferences
//                         title="Preferences"
//                         confirmLabel="Confirm"
//                         cancelLabel="Cancel"
//                         preferences={{
//                             pageSize: 6,
//                             visibleContent: ["description", "keyawsservices", "industry"],
//                         }}
//                         pageSizePreference={{
//                             title: "Page size",
//                             options: [
//                                 { value: 6, label: "6 resources" },
//                                 { value: 12, label: "12 resources" },
//                             ],
//                         }}
//                         visibleContentPreference={{
//                             title: "Select visible content",
//                             options: [
//                                 {
//                                     label: "Main distribution properties",
//                                     options: [
//                                         {
//                                             id: "description",
//                                             label: "Description",
//                                         },
//                                         { id: "type", label: "Type" },
//                                         { id: "size", label: "Size" },
//                                     ],
//                                 },
//                             ],
//                         }}
//                     />
//                 }
//             />
//         </SpaceBetween>
//     </div>
// );
