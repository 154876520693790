import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import {
  Modal,
  Table,
  Box,
  Button,
  TextFilter,
  Header,
  Pagination,
  SpaceBetween,
  ButtonDropdown,
  StatusIndicator,
  Alert,
  Flashbar,
  CollectionPreferences,
  ContentLayout,
  BreadcrumbGroup,
  Grid,
  Container,
  ColumnLayout,
  Link,
  Icon,
} from "@cloudscape-design/components";
import "./Home.css";

import img1 from "../../assets/images/img1.png";
import img2 from "../../assets/images/img2.png";
import img3 from "../../assets/images/img3.png";
import demo1 from "../../assets/images/demo1-1.png";
import demo2 from "../../assets/images/demo2-1.png";
import demo3 from "../../assets/images/demo3-1.png";
import demo4 from "../../assets/images/demo4-1.png";

import HeroHeader from "./Header";

const proprietes = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  height: "100%",
};

export default function Home(props) {
  console.log(props)
  const [isMobile, setIsMobile] = useState(false);
  const [authticated] = useOutletContext();

  const navigate = useNavigate();
  const navigateToBrowse = () => {
    if (authticated) navigate("/browseDemos");
    else navigate("/authen");
  };

  useEffect(() => {
    setIsMobile(window.innerWidth < 500)
  }, [])

  window.addEventListener(
    "resize",
    () => {
      setIsMobile(window.innerWidth < 500);
    },
    false
  );

  return (
    <ContentLayout
      // notifications={showFlashbar && <Flashbar items={[disclaimerItem]} />}
      // headerVariant={headerVariant}
      headerVariant="high-contrast"
      header={<HeroHeader />}
      defaultPadding={true}
      //   maxContentWidth={1040}
      disableOverlap={true}
    >
      <Box margin={{ top: "xl" }}>
        <Grid
          gridDefinition={
            isMobile
              ? [{ offset: 0 }, { colspan: 12 }, { colspan: 12 }]
              : [
                  { offset: { default: 2, s: 1, xs: 0, xxs: 0 } },
                  { colspan: { default: 6, l: 5, xl: 6, s: 6, xs: 6, xxs: 6 } },
                  { colspan: { default: 2, l: 3, xl: 2, s: 4, xs: 4, xxs: 5 } },
                ]
          }
        >
          <div></div>
          <div className="custom-home-main-content-area">
            <SpaceBetween direction="vertical" size="xs">
              <SpaceBetween direction="vertical" size="l">
                <div className="board">
                  <div className="board-header">
                    <Box fontSize="heading-xl" fontWeight="normal">
                      Benefits and features
                    </Box>
                    {/* <Header variant="h1">Benefits and features</Header> */}
                  </div>
                  <div className="board-content">
                    <ColumnLayout columns={2}>
                      <div>
                        <Box variant="h3" fontWeight="normal">
                          Reusability
                        </Box>
                        <Box variant="p">
                          Deliver tailored demos to your customer without having
                          to from scratch or request the assistance of a prototyping team.
                        </Box>
                      </div>
                      <div>
                        <Box variant="h3" fontWeight="normal">
                          Wide Range of Demos 
                        </Box>
                        <Box variant="p">
                          The Gen AI Repo supports a wide range of demos,
                          and the team will continue to add more Gen AI demos to the repository.
                        </Box>
                      </div>
                      <div>
                        <Box variant="h3" fontWeight="normal">
                          Easier Path to Production 
                        </Box>
                        <Box variant="p">
                          Every demo comes with detailed implementation, architecture, 
                          introductory slides, cost analysis, and sample code. This will allow the
                          SA team of AWS and Partners to support a better path to production
                          for customers
                        </Box>
                      </div>
                      {/* <div>
                        <Box variant="h3" fontWeight="normal">
                          Little to no development work
                        </Box>
                        <Box variant="p">
                          This will allow you to use general solutions for
                          specific customers without diving into code or
                          templates, and quickly allow you to demo a solution to
                          an external customer
                        </Box>
                      </div> */}
                    </ColumnLayout>
                  </div>
                </div>
                <div className="board intro-image data-am-gallery">
                  <div className="slide-container">
                    <Slide {...proprietes}>
                      <div>
                        <div
                          style={{
                            backgroundImage: `url(${demo1})`,
                          }}
                          className="home-img"
                        ></div>
                      </div>
                      <div>
                        <div
                          style={{
                            backgroundImage: `url(${demo2})`,
                          }}
                          className="home-img"
                        ></div>
                      </div>
                      <div>
                        <div
                          style={{
                            backgroundImage: `url(${demo3})`,
                          }}
                          className="home-img"
                        ></div>
                      </div>
                      <div>
                        <div
                          style={{
                            backgroundImage: `url(${demo4})`,
                          }}
                          className="home-img"
                        ></div>
                      </div>
                    </Slide>
                  </div>
                </div>
              </SpaceBetween>
            </SpaceBetween>
          </div>
          <div className="custom-home_sidebar">
            <Box margin={{ bottom: "xxl" }}>
              <Container variant="default" className="board">
                <SpaceBetween direction="vertical" size="s">
                  <Box variant="h2" fontSize="heading-m">
                    Get Started
                  </Box>
                  <p>Browse our content and chose a demo for your use-case.</p>
                  <Button variant="primary" onClick={navigateToBrowse}>
                    Browse Demos
                  </Button>
                </SpaceBetween>
              </Container>
            </Box>
            <Box margin={{ bottom: "xxl" }}>
              <div className="board">
                <div className="board-header">
                  How to use Gen AI Demos <Icon name="external" />
                </div>
                <div className="board-content">
                  <ColumnLayout borders="horizontal">
                    <Link href="#">How can I submit a Demo</Link>
                  </ColumnLayout>
                </div>
              </div>
            </Box>
            <Box margin={{ bottom: "xxl" }}>
              <div className="board">
                <div className="board-header">
                  Additional resources <Icon name="external" />
                </div>
                <div className="board-content">
                  <ColumnLayout borders="horizontal">
                    <Link href="#">FAQ</Link>
                    <Link href="#">Provide Feedback</Link>
                    <Link href="#">Email Us</Link>
                  </ColumnLayout>
                </div>
              </div>
            </Box>
          </div>
        </Grid>
      </Box>
    </ContentLayout>
  );
}
