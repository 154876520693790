import React, { useState, useEffect } from "react";
import {
  TextFilter,
  Header,
  Button,
  SpaceBetween,
  Box,
  Table,
  BreadcrumbGroup,
  Pagination,
  CollectionPreferences,
  Badge,
  Link,
  StatusIndicator,
  Modal,
  Flashbar,
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useNavigate } from "react-router-dom";
import { get, del } from "aws-amplify/api";
import { remove } from "aws-amplify/storage";
import { apiName, demosPath, myLaunchesPath, browseDemosPath } from "../../tool/api";
import { EmptyState, checkAdmin } from "../../tool/tool"
import "./My_Launches.css";
// import demoData from "./demoData";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
// import MyDemo from "../DeleteDemo/DeleteDemo";

const successMes = "Delete success";
const errorMess = "Error! An error occurred. Please try again later";

export default function My_launches() {
  const [selectedItems, setSelectedItems] = useState([]);
  const [CurrentDemo, setCurrentDemo] = useState(true);
  const [myDemos, setMyDemos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);
  const [flashItem, setFlashItem] = useState([]);
  const navigate = useNavigate();

  const [preferences, setPreferences] = useState({
    pageSize: 10,
    columnDisplay: [
      { id: "name", visible: true },
      { id: "status", visible: true },
      { id: "started", visible: true },
      { id: "lastUpdated", visible: true },
    ],
  });

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(myDemos, {
    filtering: {
      empty: <EmptyState title="No demo" />,
      noMatch: (
        <EmptyState
          title="No matches"
          action={
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });

  const NavigateToForm = () => {
    navigate("/createDemo");
  };

  useEffect(() => {
    getMyLaunches();
  }, []);

  const [statusEdit, setStatusEdit] = useState(true);
  const [statusDetail, setStatusDetail] = useState(true);
  const [statusDelete, setStatusDelete] = useState(true);

  const getMyLaunches = async () => {
    setLoading(true);
    const result = await checkAdmin();
    const path = result ? browseDemosPath : myLaunchesPath
    console.log(result)
    try {
      const restOperation = get({
        apiName: apiName,
        path: demosPath + path,
      });
      const { body } = await restOperation.response;
      const json = await body.json();
      console.log(json);
      setMyDemos(json);
      setLoading(false);
      // console.log(setMyDemos);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleClick = (value, demo) => {
    switch (value.id) {
      case "rm":
        setModalVisible(true);
        break;
      case "edt":
        navigate(`/editDemo/${selectedItems[0]?.id}`, {
          state: selectedItems[0],
        });
        break;
      default:
        break;
      case "view":
        navigate(`/viewDemo/${selectedItems[0]?.id}`, {
          state: selectedItems[0],
        });
    }
  };

  const resetFail = () => {
    setbtnDisable(false);
    setBtnLoading(false);
    setModalVisible(false);
    setFlashItem([
      {
        type: "error",
        content: errorMess,
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => setFlashItem([]),
        id: "error_message",
      },
    ]);
  };

  const resetSuccess = () => {
    setbtnDisable(false);
    setBtnLoading(false);
    setModalVisible(false);
    setFlashItem([
      {
        type: "success",
        content: successMes,
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => setFlashItem([]),
        id: "success_message",
      },
    ]);
    setSelectedItems([]);
  };

  const deleteDemos = async (event) => {
    event.preventDefault(true);
    setBtnLoading(true);
    var demosList = [...myDemos];
    var i = 0;
    for (i = 0; i < selectedItems.length; i++) {
      const demo = selectedItems[i];
      try {
        // Delete resources in S3
        if (demo.architectture) {
          await remove({
            path: demo.architectture,
          });
        }

        if (demo.demoVideo) {
          await remove({
            path: demo.demoVideo,
          });
        }

        if (demo.documents) {
          await remove({
            path: demo.documents,
          });
        }

        // Delete record in DynamoDB
        const restOperation = del({
          apiName: apiName,
          path: demosPath + `/${selectedItems[i].id}`,
        });
        await restOperation.response;
        demosList = demosList.filter((item) => item.id !== selectedItems[i].id);

        // check finish array
        if (i === selectedItems.length - 1) {
          resetSuccess();
          setMyDemos(demosList);
        }
      } catch (error) {
        console.log(error);
        resetFail();
      }
    }
  };

  return (
    <div className="myLaunches">
      <SpaceBetween size="l">
        <Flashbar items={flashItem} />
        <BreadcrumbGroup
          items={[
            { text: "Home", href: "#" },
            {
              text: "Demos",
              href: "#/myDemos",
            },
          ]}
          ariaLabel="Breadcrumbs"
        />

        <Table
          {...collectionProps}
          className="board"
          renderAriaLive={({ firstIndex, lastIndex, totalItemsCount }) =>
            `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
          }
          onSelectionChange={({ detail }) => {
            setSelectedItems(detail?.selectedItems ?? []);
            if (detail.selectedItems.length > 1) {
              setStatusDetail(true);
              setStatusEdit(true);
            } else if (detail.selectedItems.length < 1) {
              setStatusDetail(true);
              setStatusEdit(true);
              setStatusDelete(true);
            } else {
              setStatusDetail(false);
              setStatusEdit(false);
              setStatusDelete(false);
            }
          }}
          selectedItems={selectedItems}
          ariaLabels={{
            selectionGroupLabel: "Items selection",
            allItemsSelectionLabel: ({ selectedItems }) =>
              `${selectedItems.length} ${
                selectedItems.length === 1 ? "item" : "items"
              } selected`,
            itemSelectionLabel: ({ selectedItems }, item) => item.nameDemo,
          }}
          columnDefinitions={[
            {
              id: "name",
              header: "Name",
              cell: (item) => item.name,
              sortingField: "nameDemo",
              isRowHeader: true,
            },
            {
              id: "status",
              header: "Status",
              cell: (item) => (
                <StatusIndicator
                  type={item.status === "Disabled" ? "error" : "success"}
                >
                  {item.status}
                </StatusIndicator>
              ),
            },
            {
              id: "started",
              header: "Started",
              cell: (item) => (
                <StatusIndicator type={!item.started ? "error" : "success"}>
                  {!item.started ? "No" : "Yes"}
                </StatusIndicator>
              ),
            },
            {
              id: "lastUpdated",
              header: "Last Updated",
              cell: (item) => item.lastUpdate,
            },
          ]}
          columnDisplay={preferences.columnDisplay}
          enableKeyboardNavigation
          items={items}
          loading={loading}
          loadingText="Loading resources"
          selectionType="multi"
          trackBy="id"
          resizableColumns
          stripedRows
          //variant="full-page"
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No launches</b>
                <b>You haven't launched any demos yet</b>
                <Button onClick={NavigateToForm} variant="primary">
                  Create Demos
                </Button>
              </SpaceBetween>
            </Box>
          }
          filter={
            <div className="input-container">
              <TextFilter
                {...filterProps}
                filteringPlaceholder="Find resources"
              />
            </div>
          }
          header={
            <Header
              counter={
                selectedItems.length
                  ? `(${selectedItems.length}/` + `${myDemos.length})`
                  : "(" + myDemos.length + ")"
              }
              header={
                <Link variant="primary" href="/launch/app">
                  Launches
                </Link>
              }
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <ButtonDropdown
                    onItemClick={(e) => handleClick(e.detail)}
                    items={[
                      {
                        text: "Deactivate",
                        id: "deactive",
                        disabled: false,
                      },
                      {
                        text: "Activate",
                        id: "active",
                        disabled: false,
                      },
                      {
                        text: "View details",
                        id: "view",
                        disabled: statusDetail,
                      },
                      {
                        text: "Edit",
                        id: "edt",
                        disabled: statusEdit,
                      },
                      {
                        text: "Delete",
                        id: "rm",
                        disabled: statusDelete,
                      },
                    ]}
                  >
                    Actions
                  </ButtonDropdown>
                  <Button
                    variant="primary"
                    onClick={() => navigate("/createDemo")}
                  >
                    Create Demos
                  </Button>
                </SpaceBetween>
              }
            >
              My Demos
            </Header>
          }
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              onConfirm={({ detail }) => setPreferences(detail)}
              preferences={preferences}
              pageSizePreference={{
                title: "Page size",
                options: [
                  { value: 10, label: "10 resources" },
                  { value: 20, label: "20 resources" },
                ],
              }}
              setPreferences={setPreferences}
              contentDisplayPreference={{
                options: [
                  {
                    id: "launchid",
                    label: "Launch Id",
                  },
                  { id: "demoid", label: "Demo Id" },
                  { id: "status", label: "Status" },
                  { id: "started", label: "Started" },
                  { id: "lastupdate", label: "Last Update" },
                ],
              }}
            />
          }
        />

        <Modal
          onDismiss={() => setModalVisible(false)}
          visible={modalVisible}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={() => setModalVisible(false)}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  disable={btnDisable}
                  loading={btnLoading}
                  onClick={deleteDemos}
                >
                  OK
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Modal title"
        >
          Are you sure deleting the demos?
        </Modal>
      </SpaceBetween>
    </div>
  );
}
