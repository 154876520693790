import React, { useState, useEffect } from "react";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import Link from "@cloudscape-design/components/link";
import Grid from "@cloudscape-design/components/grid";
import SpaceBetween from "@cloudscape-design/components/space-between";

export default function HeroHeader(props) {
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    setIsMobile(window.innerWidth < 500)
  }, [])

  window.addEventListener(
    "resize",
    () => {
      setIsMobile(window.innerWidth < 500);
    },
    false
  );

  return (
    <Box data-testid="hero-header" padding={{ top: "xl", bottom: "xxxl" }}>
      <Grid
        gridDefinition={
          isMobile
            ? [{ offset: 0 }, { colspan: 12 }, { colspan: 12 }]
            : [
              { offset: { default: 2, s: 1, xs: 0, xxs: 1 } },
              { colspan: { default: 6, l: 5, xl: 6, s: 6, xs: 6, xxs: 9 } },
              { colspan: { default: 2, l: 3, xl: 2, s: 4, xs: 4, xxs: 0 } },
            ]
        }
      >
        <div></div>
        <div className="custom-home__header">
          <Box variant="h1" fontSize="display-l">
            GenAI Demo Repos
          </Box>
          <Box
            variant="p"
            color="text-body-secondary"
            margin={{ top: "xxs", bottom: "s" }}
            fontSize="display-l"
            fontWeight="light"
          >
            Accelerate Gen AI Adoption
          </Box>
          <Box
            variant="p"
            color="text-body-secondary"
            margin={{ top: "xxs", bottom: "s" }}
          >
            The Gen AI Demo Repository illuminates the path to innovation by
            providing a centralized hub for harnessing the boundless
            capabilities of AWS generative AI services. More than just a
            collection of demos, it offers a comprehensive architecture, sample
            code, and implementation guides. This empowers AWS customers and
            partners to seamlessly integrate cutting-edge AI technologies into
            their applications, accelerating development and unleashing new
            possibilities faster than ever before.
          </Box>
        </div>
        <div></div>
      </Grid>
    </Box>
  );
}
