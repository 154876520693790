import moment from "moment";
import { Box } from "@cloudscape-design/components";
import { getCurrentUser, fetchUserAttributes } from "aws-amplify/auth";

export function EmptyState({ title, subtitle, action }) {
  return (
    <Box textAlign="center" color="inherit">
      <Box variant="strong" textAlign="center" color="inherit">
        {title}
      </Box>
      <Box variant="p" padding={{ bottom: "s" }} color="inherit">
        {subtitle}
      </Box>
      {action}
    </Box>
  );
}

export const checkAdmin = async () => {
  const user = await fetchUserAttributes();
  return user["custom:role"] === "admin" ? true : false;
}

export function transformDateTime(createAt) {
    const date = moment(createAt);
    return date.calendar(null, {
      sameDay: "LT",
      lastDay: "MMM D LT",
      lastWeek: "MMM D LT",
      sameElse: "l",
    });
  }

export async function checkLogin(){
  try{
    const user = await getCurrentUser();
    return true;
  }catch(e){
    return false;
  }
}