import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation, Navigate } from "react-router-dom";
import {
  AppLayout,
  SideNavigation,
  //BreadcrumbGroup,
} from "@cloudscape-design/components";
import Demo_detail from "./Launches/Demo_detail";
import { getCurrentUser, fetchUserAttributes } from "aws-amplify/auth";
import { signOut } from "aws-amplify/auth";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { get, put } from "aws-amplify/api";
import {
  apiName,
  demosPath,
  myLaunchesPath,
  browseDemosPath,
  featuredDemosPath
} from "../tool/api";
import { ConsoleLogger } from "aws-amplify/utils";

export default function Management(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [authticated, setAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState("user");
  const [allDemos, setAllDemos] = useState([]);
  const [error, setError] = useState(null);
  const [activeHref, setActiveHref] = useState("");

  useEffect(() => {
    setActiveHref(location.pathname.split("/").pop());
    // ionViewCanEnter();
  }, []);

  const [currentUser, setCurrentUser] = useState();
  useEffect(() => {
    getUserSessions();
  }, []);

  // Lấy giá trị từ getCurrentUser() và lưu vào state
  const getUserSessions = async () => {
    try {
      const user = await getCurrentUser();
      setCurrentUser(user);
      setAuthenticated(true);
      const userAttributes = await fetchUserAttributes();
      // console.log(userAttributes);
      setUserRole(userAttributes["custom:role"]);
      getAllDemos();
    } catch (error) {
      setAuthenticated(false);
      console.error("Error fetching current user:", error);
    }
  };

  const getAllDemos = async () => {
    try {
      const restOperation = get({
        apiName: apiName,
        path: demosPath + featuredDemosPath,
      });
      const { body } = await restOperation.response;
      const json = await body.json();
      console.log(json);
      setAllDemos(json);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  return (
    <>
        <AppLayout
          className="management"
          toolsHide={true}
          // breadcrumbs={
          //   <BreadcrumbGroup
          //     items={[
          //       { text: "", href: "/home"},
          //       // { text: "My Launches", href: "/home/myLaunches" },
          //     ]}
          //     ariaLabel="Breadcrumbs"
          //   />
          // }
          disableContentPaddings="true"
          headerVariant="high-contrast"
          navigation={
            <SideNavigation
              activeHref={activeHref}
              header={{ href: "/", text: "GenAI Demo Repos" }}
              // className="side-nav-custom"
              onFollow={(event) => {
                if (!event.detail.external) {
                  event.preventDefault();
                  const href =
                    event.detail.href === "/" ? "home" : event.detail.href;
                    setActiveHref(href);
                  if (event.detail.href.includes("demoDetail")){
                    navigate(`/${href}`, {state: allDemos[event.detail.target.index]});
                  }else{
                    navigate(`/${href}`);
                  }
                }
              }}
              items={
                authticated
                  ? (
                    userRole === "user" ? [
                      {
                        type: "link",
                        text: "Home",
                        href: "home",
                      },
                      { type: "divider" },
                      {
                        type: "link",
                        text: "Browse All Demos",
                        href: "browseDemos",
                      },
                      {
                        type: "divider",
                      },
                      {
                        type: "section",
                        text: "Featured Demos",
                        items: allDemos.map((item, index) => {
                          return {
                            type: "link",
                            text: item.name,
                            href: `demoDetail/${item.id}`,
                            target: {index}
                          };
                        }),
                        // defaultExpanded: false,
                      },
                      // {
                      //   type: "divider",
                      // },
                      // {
                      //   type: "section",
                      //   text: "Demos",
                      //   items: [{}],
                      // },
                      {
                        type: "divider",
                      },
                      {
                        type: "section",
                        text: `${currentUser.username}`,
                        items: [
                          {
                            type: "link",
                            text: "Sign out",
                            href: "signout",
                          },
                        ],
                      },
                    ] : [
                      {
                        type: "link",
                        text: "Home",
                        href: "home",
                      },
                      {
                        type: "link",
                        text: "My Demos",
                        href: "myDemos",
                      },
                      { type: "divider" },
                      {
                        type: "link",
                        text: "Browse All Demos",
                        href: "browseDemos",
                      },
                      {
                        type: "divider",
                      },
                      {
                        type: "section",
                        text: "Featured Demos",
                        items: allDemos.map((item, index) => {
                          return {
                            type: "link",
                            text: item.name,
                            href: `demoDetail/${item.id}`,
                            target: {index}
                          };
                        }),
                        // defaultExpanded: false,
                      },
                      // {
                      //   type: "divider",
                      // },
                      // {
                      //   type: "section",
                      //   text: "Demos",
                      //   items: [{}],
                      // },
                      {
                        type: "divider",
                      },
                      {
                        type: "section",
                        text: `${currentUser.username}`,
                        items: [
                          {
                            type: "link",
                            text: "Sign out",
                            href: "signout",
                          },
                        ],
                      },
                    ]
                  )
                  : [
                      {
                        type: "link",
                        text: "Home",
                        href: "home",
                      },
                      // {
                      //   type: "link",
                      //   text: "Browse All Demos",
                      //   href: "browseDemos",
                      // },
                      {
                        type: "divider",
                      },
                      {
                        type: "link",
                        text: <span style={{color: "black", fontWeight: "700", fontSize: "16px"}}>Sign in</span>,
                        href: "authen",
                      },
                      {
                        type: "link",
                        text: <strong style={{color: "black", fontWeight: "700", fontSize: "16px"}}>Sign up</strong>,
                        href: "authen",
                      },
                    ]
              }
            />
          }
          content={<Outlet context={[authticated]}/>}
        />
    </>
  );
}
