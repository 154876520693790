import React, { useState } from 'react';
import { post, put, del } from "aws-amplify/api";
import { useNavigate, useLocation, parsePath, Navigate } from "react-router-dom";
import { apiName, browseDemos, browseDemosPath, demosPath, myLaunchesPath } from "../../tool/api";
import {
    Box,
    Button,
    Container,
    Header,
    Input,
    SpaceBetween,
    TopNavigation,
    Flashbar,
    Alert,
    Modal
} from '@cloudscape-design/components';
import { API } from "aws-amplify";
import My_launches from '../Launches/My_Launches';
import { remove } from 'aws-amplify/storage';
const successMes = "Delete success";
const errorMes = "Error! An error occurred. Please try again later";

const MyDemo = () => {
    const [selectedItems, setSelectedItems] = React.useState([]);


    const [demos, setDemos] = useState([]);
    const [currentDemo, setCurrentDemo] = useState();
    const [loading, setLoading] = useState(false);
    const [noticeMessage, setNoticeMessage] = useState("");
    const [visible, setVisible] = useState(false);
    const [disable, setDisable] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [flashItem, setFlashItem] = useState([]);
    const navigate = useNavigate();



    const confirmDelete = () => {
        setNoticeMessage("Are you sure deleting the courses?");
        setVisible(true);
    };
    const resetFail = () => {
        setDeleted(false);
        setDisable(false);
        setVisible(false);
        setFlashItem([
            {
                type: "error",
                content: errorMes,
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => setFlashItem([]),
                id: "error_message",
            },
        ]);
    };

    const resetSuccess = () => {
        setDisable(false);
        setDeleted(false);
        setVisible(false);
        setFlashItem([
            {
                type: "success",
                content: successMes,
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: () => setFlashItem([]),
                id: "success_message",
            },
        ]);
        setSelectedItems([]);
    };
    const deleteDemo = async () => {
        // setDeleted(true);
        // let demoList = demos;
        // let countDeleteItems = currentDemo ? 1 : selectedItems.length;
        // const deleteItems = currentDemo ? currentDemo : selectedItems
        // for (let i = 0; i < countDeleteItems; i++) {
        //     try {
        //         restOperation = del(apiName, demosPath + "/object/" + deleteItems[i].ID);
        //         demoList = demoList.filter(demo => demo.ID != deleteItems[i].ID);
        //         if (i === countDeleteItems - 1) {
        //             resetSuccess();
        //             setDemos(demoList);
        //         }
        //         await restOperation.response;
        //         console.log('DELETE call succeeded');
        //     } catch (error) {
        //         resetFail();
        //         console.log(error);
        //     }
        // }
        setDisable(true);
        setDeleted(true);
        try {
            const restOperation = del({
                apiName: apiName,
                path: demosPath + browseDemosPath + myLaunchesPath,
            });
            await restOperation.response;
            console.log('DELETE call succeeded');
            resetSuccess();
            // navigate("/myLaunches");
        }

        catch (e) {
            console.log('DELETE call failed: ');
            resetFail();
        }
    }
    // const deleteData = async () => {
    //     try {
    //         await remove({
    //             path: demosPath + myLaunchesPath,
    //             // Alternatively, path: ({identityId}) => `protected/${identityId}/album/2024/1.jpg`
    //         });
    //     } catch (error) {
    //         console.log('Error ', error);
    //     }
    // }
    return (
        <>

            <Alert
                header="Delete Demo"
                statusIconAriaLabel="Info"
                action={<Button
                    variant="primary"
                    disable={disable}
                    loading={deleted}
                    onClick={() => deleteDemo()}
                    style={{ padding: "20px" }}
                >
                    Yes, I want to delete it!!!
                </Button>}>
                {<Button
                    variant="primary"
                    disable={disable}
                    loading={deleted}
                    onClick={() => <Navigate to="/myDemos" />}
                >
                    No, back to my demos
                </Button>}

            </Alert >

            <Flashbar items={flashItem} />
        </>
    )
};
export default MyDemo;