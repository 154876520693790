import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Import React Router
import {
  createBrowserRouter,
  createHashRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import { applyTheme } from '@cloudscape-design/components/theming';

// Import views
import Management from "./views/Management";
import Home from "./views/Home/Home";
import My_Launches from './views/Launches/My_Launches';
import Browse_Demo from './views/Launches/Browse_Demo';
import Demo_detail from './views/Launches/Demo_detail';
import Submit_Demo from './views/CreateDemo/Submit_Demo';
//import { getCurrentUser } from 'aws-amplify/auth';
import AuthenForm from './views/Authentication/AuthenForm';
import Sign_out from './views/Authentication/Sign_out';
import UpdateDemo from './views/UpdateDemo/UpdateDemo'
import DeleteDemo from './views/DeleteDemo/DeleteDemo'
//import Sign_in from './views/Authentication/Sign_in';
//import Sign_out from './views/Authentication/Sign_out';
//import Sign_up from './views/Authentication/Sign_up';
import 'aws-amplify/auth/enable-oauth-listener';

// Configure Amplify
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { isModelFieldType } from 'aws-amplify/datastore';
Amplify.configure(config);


//const { username, userId, signInDetails } = await getCurrentUser();


//import "react-slideshow-image/dist/styles.css";
applyTheme({
  theme: {
    tokens: {
      colorBackgroundLayoutMain: {
        light: '#f2f3f3',
        // light: 'white',
        dark: '{colorGrey900}',
      },
      colorTextButtonPrimaryDefault: "#fff",
      colorTextButtonNormalDefault: "#545b64",
      colorBackgroundButtonPrimaryDefault: "#ff9900",
      colorBackgroundButtonPrimaryHover: "#ec7211",
      colorBackgroundButtonNormalHover: "#ec7211",
      colorBackgroundButtonPrimaryActive: "#eb5f07",
      colorBackgroundButtonNormalActive: "#eaeded",
      colorBackgroundButtonNormalHover: "#fafafa",
      colorBorderButtonNormalDefault: "#545b64",
      colorBorderButtonNormalHover: "#ec7211",
      colorBorderButtonNormalActive: "#ec7211",
      // colorBorderTabsUnderline: "#16191f",
      borderRadiusContainer: "0",
      borderRadiusButton: "0",
      borderRadiusBadge: "15px",
      colorBorderDividerDefault: "#eaeded",
      fontFamilyBase: "Amazon Ember",
      colorTextLinkButtonNormalDefault: "#0073bb",
      colorTextLinkDefault: "#0073bb",
      colorTextAccent: "#0073bb",
      colorBackgroundNotificationBlue: "#0073bb"
    },
    contexts: {
      // Values for visual contexts. Unless specified, default values will be applied
      "top-navigation": {
        tokens: {
          // colorTextTopNavigationTitle: '#EC7211',
        },
      },
      header: {
        tokens: {
          colorBackgroundLayoutMain: "#232f3e",
        },
      },
    },
  },
});


const router = createHashRouter([
  {
    path: "/",
    element: <Management />,
    children: [
      {
        path: "",
        element: <Navigate to="/home" replace />,
      },
      {
        path: "home",
        element: <Home />,
      },
      // {
      //   path: "publicLectures",
      //   element: <PublicLectures />,
      // },
      {
        path: "myDemos",
        element: <My_Launches />,
      },
      {
        path: "browseDemos",
        element: <Browse_Demo />,
      },
      {
        path: "demoDetail/:id",
        element: <Demo_detail />,
      },
      {
        path: "createDemo",
        element: <Submit_Demo />,
      },
      {
        path: "/editDemo/:id",
        element: <UpdateDemo />,
      },
      {
        path: "/viewDemo/:id",
        element: <Demo_detail />,
      },
      {
        path: "/removeDemo/:id",
        element: <DeleteDemo />
      }
      // {
      //   path: "signIn",
      //   element: <Sign_in />,
      // },
      // {
      //   path: "signUp",
      //   element: <Sign_up />,
      // },
      // {
      //   path: "signOut",
      //   element: <Sign_out />,
      // },

    ],
  },
  // {
  //   path: "/",
  //   element: <AuthenForm />,
  //   children: [
  //     {
  //       path: "signUp",
  //       element: <AuthenForm />,
  //     },
  //     {
  //       path: "signIn",
  //       element: <AuthenForm />,
  //     }
  //   ]
  // }
  {
    path: "/authen",
    element: <AuthenForm />,
  },
  // {
  //   path: "/authen",
  //   element: <AuthenForm />,
  // },
  {
    path: "/signout",
    element: <Sign_out />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
