import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Link,
  ColumnLayout,
  Badge,
  Icon,
  Container,
  Header,
  SpaceBetween,
  Button,
  Tabs,
  BreadcrumbGroup,
  TextFilter,
  FormField,
  Table,
  Modal,
  Alert,
  Input,
  Form,
} from "@cloudscape-design/components";
import Markdown from "markdown-to-jsx";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import {
  Player,
  LoadingSpinner,
  BigPlayButton,
  ControlBar,
  ReplayControl,
  ForwardControl,
  PlaybackRateMenuButton,
  VolumeMenuButton,
  FullscreenToggle,
} from "video-react";
import loadingGif from "../../assets/images/loading1.gif";
import { useLocation, useHistory } from "react-router-dom";
import "video-react/dist/video-react.css";
import "./Demo_Detail.css";
import { post, put, del, get } from "aws-amplify/api";
import { apiName, feedbackPath, myFBPath, demosPath } from "../../tool/api";
import { getUrl } from "aws-amplify/storage";
import { checkLogin } from "../../tool/tool";

// import img1 from "../../assets/images/img1.png";
// import img2 from "../../assets/images/img2.png";
// import img3 from "../../assets/images/img3.png";
// import imgArchitech from "../../assets/images/aws-diagram.png";

const proprietes = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true,
  height: "100%",
};

function VideoDemo(props) {
  useEffect(() => {
    // player.subscribeToStateChange(handleStateChange.bind());
  });

  function handleStateChange(state, prevState) {
    // if (state.ended) props.handleVideoEnded();
  }
  const classNames = ["lesson-video"];

  return (
    <>
      {props.type === "s3" ? (
        <div className="video-lesson intro-image">
          <Player
            // ref={(player) => {
            //   player = player;
            // }}
            // className={classNames.join(' ')}
            autoPlay
            playsInline
            fluid={false}
            height="100%"
            width="100%"
            src={props.videoSrc}
          >
            <LoadingSpinner />
            <BigPlayButton position="center" />
            <ControlBar>
              <ReplayControl seconds={5} order={2.1} />
              <ForwardControl seconds={5} order={2.2} />
              <PlaybackRateMenuButton
                rates={[2, 1.5, 1.25, 1, 0.9, 0.75]}
                order={7}
              />
              <VolumeMenuButton order={8} />
            </ControlBar>
          </Player>
        </div>
      ) : (
        <div className="video-lesson intro-image">
          <iframe
            style={{ width: "100%", height: "auto" }}
            src={props.videoSrc}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      )}
    </>
  );
}

const Feedback = (props) => {
  const [mode, setMode] = useState("display");
  const [isUpdating, setIsUpdating] = useState(false);

  const updateFeedback = async () => {
    setIsUpdating(true);
    const restOperation = post({
      apiName: apiName,
      path: feedbackPath,
      options: {
        body: props.userFeedback,
      },
    });
    const response = await restOperation.response;
    console.log(response);
    setMode("display");
    setIsUpdating(false);
  };
  console.log(props.userFeedback);
  return (
    <>
      {Object.keys(props.userFeedback).length === 0 ? (
        <></>
      ) : mode === "display" ? (
        <Container
          header={
            <Header
              variant="h2"
              actions={<Button onClick={() => setMode("edit")}>Edit</Button>}
            >
              Your Feedback
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="s">
            <ValueWithLabel label="Your customer name">
              {props.userFeedback.customer || "-"}
            </ValueWithLabel>
            <ValueWithLabel label="Your project name">
              {props.userFeedback.project || "-"}
            </ValueWithLabel>
            <ValueWithLabel label="Your improvement idea/ Feedback for this demo">
              {props.userFeedback.feedback || "-"}
            </ValueWithLabel>
          </SpaceBetween>
        </Container>
      ) : (
        <Container
          header={
            <Header
              variant="h2"
              actions={<Button onClick={updateFeedback}>Update</Button>}
            >
              Edit Feedback
            </Header>
          }
        >
          <SpaceBetween>
            <FormField label="Your customer name">
              <Input
                value={props.userFeedback.customer}
                onChange={(event) =>
                  props.setUserFeedback({
                    ...props.userFeedback,
                    customer: event.detail.value,
                  })
                }
              />
            </FormField>
            <FormField label="Your project name">
              <Input
                value={props.userFeedback.project}
                onChange={(event) =>
                  props.setUserFeedback({
                    ...props.userFeedback,
                    project: event.detail.value,
                  })
                }
              />
            </FormField>
            <FormField label="Your improvement idea/ Feedback for this demo">
              <Input
                value={props.userFeedback.feedback}
                onChange={(event) =>
                  props.setUserFeedback({
                    ...props.userFeedback,
                    feedback: event.detail.value,
                  })
                }
              />
            </FormField>
          </SpaceBetween>
        </Container>
      )}
    </>
  );
};

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box variant="awsui-key-label" color="text-body-secondary">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

function DemoDetail() {
  const [videoUrl, setVideoUrl] = useState("");
  const [archUrl, setArchUrl] = useState("");
  const [srcCodeUrl, setSrcCodeUrl] = useState("");
  const [gitUrl, setGitUrl] = useState("");
  const [slideUrl, setSlideUrl] = useState("");
  const [diagramUrl, setDiagramUrl] = useState("");
  const [uiSampleUrl, setUISampleUrl] = useState([]);
  const [guide, setGuide] = useState();
  const [videoType, setVideoType] = useState("");
  const [modalVisible, setModalVisible] = React.useState(false);
  const [customerName, setCustomerName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [feedback, setFeedBack] = useState("");
  const [userFeedback, setUserFeedback] = useState({
    userId: "",
    demoId: "",
    customer: "",
    project: "",
    feedback: "",
  });
  const [btnLoading, setBtnLoading] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const prevData = location.state;

  useEffect(() => {
    document.title = "Demo";
    getData();
  }, [prevData]);

  const getDemoData = async (id) => {
    const restOperation = get({
      apiName: apiName,
      path: demosPath + `/${id}`,
    });

    const { body } = await restOperation.response;
    const json = await body.json();
    return json;
  };

  const getData = async () => {
    const authen = await checkLogin();
    if (!authen) {
      const path = window.location.hash.replace("#", "");
      navigate("/authen", { state: { path: path } });
      return;
    }
    if (!prevData) {
      setLoading(true);
      const id = window.location.hash.split("/")[2];
      const json = await getDemoData(id);
      // const restOperation = get({
      //   apiName: apiName,
      //   path: demosPath + `/${id}`,
      // });
      // restOperation.response
      //   .then((res) => res.body.json())
      //   .then((json) => {
      //     setData(json);
      //     getFeedBack(json.id);
      //     getS3Resources(json).then(() => setLoading(false));
      //   });
      setData(json);
      getFeedBack(json.id);
      getS3Resources(json).then(() => setLoading(false));
    } else if (!prevData.hasOwnProperty("demoVideo")) {
      setLoading(true);
      const restOperation = get({
        apiName: apiName,
        path: demosPath + `/${prevData.id}`,
      });

      restOperation.response
        .then((res) => res.body.json())
        .then((json) => {
          setData(json);
          getFeedBack(json.id);
          getS3Resources(json).then(() => setLoading(false));
        });
    } else {
      setData(prevData);
      getFeedBack(prevData.id);
      getS3Resources(prevData);
      setLoading(false);
    }
  };

  const getFeedBack = async (id) => {
    const restOperation = get({
      apiName: apiName,
      path: feedbackPath + myFBPath,
      options: {
        queryParams: {
          demoId: id,
        },
      },
    });

    restOperation.response
      .then((res) => res.body.json())
      .then((json) => setUserFeedback(json));
  };

  const getS3Resources = async (data) => {
    if (data.demoVideo) {
      setVideoType("s3");
      getResourceUrl(data.demoVideo).then((data) =>
        setVideoUrl(data.url.toString())
      );
    } else {
      setVideoType("youtube");
      setVideoUrl(data.youtube);
    }

    if (data.architecture) {
      getResourceUrl(data.architecture).then((data) =>
        setArchUrl(data.url.toString())
      );
    }

    if (data.srcCode) {
      getResourceUrl(data.srcCode).then((data) =>
        setSrcCodeUrl(data.url.toString())
      );
    }

    if (data.referUrl) {
      let newReferUrl = data.referUrl;
      newReferUrl.map((item) => setGitUrl(item.url));
    }

    if (data.slide) {
      getResourceUrl(data.slide).then((data) =>
        setSlideUrl(data.url.toString())
      );
    }

    if (data.diagram) {
      getResourceUrl(data.diagram).then((data) =>
        setDiagramUrl(data.url.toString())
      );
    }
    console.log(data);
    if (data.uiSample.length > 0) {
      var uiDemoUIUrl = [];
      data.uiSample.map((item, index) => {
        getResourceUrl(item).then((res) => {
          uiDemoUIUrl.push(res.url.toString());
          if (index === data.uiSample.length - 1) {
            setUISampleUrl(uiDemoUIUrl);
          }
        });
      });
    }

    if (data.guideDoc) {
      getResourceUrl(data.guideDoc).then((data) =>
        readDocs(data.url.toString())
      );
    }
  };

  const readDocs = (guideFile) => {
    fetch(guideFile)
      .then((response) => response.text())
      .then((text) => {
        setGuide(text);
      });
  };

  const getResourceUrl = async (path) => {
    const getUrlResult = await getUrl({
      path: path,
      options: {
        validateObjectExistence: false, // Check if object exists before creating a URL
        // useAccelerateEndpoint: true // Whether to use accelerate endpoint
      },
    });
    // console.log(getUrlResult);
    return getUrlResult;
  };

  // link download
  const handleDownLoad = (src, name) => {
    const link = document.createElement("a");
    link.href = src;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // check require full info
  const checkRequire = () => {
    if (customerName && projectName && feedback) {
      return true;
    }
    return false;
  };

  // handle feedback and download code
  const uploadFeedbackAndDownload = async (e) => {
    e.preventDefault();
    setbtnDisable(true);
    setBtnLoading(true);
    if (checkRequire()) {
      // upload feedback
      const inputData = {
        demoId: data.id,
        customer: customerName,
        project: projectName,
        feedback: feedback,
      };
      const restOperation = post({
        apiName: apiName,
        path: feedbackPath,
        options: {
          body: inputData,
        },
      });

      const response = await restOperation.response;
      setUserFeedback(inputData);

      // download resource
      handleDownLoad(srcCodeUrl, data.srcCode.split("/")[2]);
    }
    setbtnDisable(false);
    setBtnLoading(false);
    setModalVisible(false);
  };

  return (
    <>
      <div className="demodetail">
        <Box margin={{ bottom: "xxxl" }}>
          <BreadcrumbGroup
            items={[
              { text: "Home", href: "#" },
              { text: "Browse", href: "#/browseDemos" },
              { text: `${prevData?.name}` },
            ]}
            ariaLabel="Breadcrumbs"
          />
        </Box>
        {!loading ? (
          <SpaceBetween direction="vertical" size="m">
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  {/* <Button>Request Support</Button>
                  <Button variant="primary">Select This Demo</Button> */}
                </SpaceBetween>
              }
            >
              <Box variant="div" fontSize="heading-xl">
                {data.name}
              </Box>
            </Header>
            {/* <Container header={<Header headingTagOverride="h3">Detail</Header>}> */}
            <div className="board">
              <div className="board-header">Details</div>
              <div className="board-content">
                <ColumnLayout columns={3} variant="text-grid">
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Description">
                      {data.description}
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    {/* <ProgressBar variant="key-value" label="Status" value={37} description="Update in progress" /> */}
                    <ValueWithLabel label="Services Used">
                      <SpaceBetween size="xs" direction="horizontal">
                        {data.serviceUsed?.map((item, index) => (
                          <Badge color="blue">{item}</Badge>
                        )) || "_"}
                      </SpaceBetween>
                    </ValueWithLabel>
                    <ValueWithLabel label="Industry">
                      <SpaceBetween size="xs" direction="horizontal">
                        {data.industry?.map((item, index) => (
                          <Badge color="blue">{item}</Badge>
                        )) || "_"}
                      </SpaceBetween>
                    </ValueWithLabel>
                    <ValueWithLabel label="Technology">
                      <SpaceBetween size="xs" direction="horizontal">
                        {data.technology?.map((item, index) => (
                          <Badge color="blue">{item}</Badge>
                        )) || "_"}
                      </SpaceBetween>
                    </ValueWithLabel>
                  </SpaceBetween>
                  <SpaceBetween size="l">
                    <ValueWithLabel label="Author(s)">
                      <div className="custom-wrapping">
                        {data.authors?.join(", ")}
                      </div>
                    </ValueWithLabel>
                    <ValueWithLabel label="Version">
                      {data.version ? data.version : "0.01"}
                    </ValueWithLabel>
                    {/* <ValueWithLabel label="Last Changed">
                      {data.lastUpdate ? transformDateTime(data.lastUpdate) : ""}
                    </ValueWithLabel> */}
                    <ValueWithLabel label="Contact Email">
                      {data.email}
                    </ValueWithLabel>
                    <ValueWithLabel label="Estimated Launch Time">
                      {data.duration}
                    </ValueWithLabel>
                    <ValueWithLabel label="Demo cost">
                      {data.price}
                    </ValueWithLabel>
                  </SpaceBetween>
                </ColumnLayout>
              </div>
            </div>

            <SpaceBetween size="xl">
              <Container>
                <Tabs
                  tabs={[
                    {
                      label: "Demo UI",
                      id: "first",
                      content: (
                        <div className="intro-image data-am-gallery">
                          <div className="slide-container">
                            <Slide {...proprietes}>
                              {uiSampleUrl.length > 0 &&
                                uiSampleUrl.map((item, index) => (
                                  <div key={index}>
                                    {/* <div
                                  className="img-demo"
                                  style={{
                                    backgroundImage: `url(${item})`,
                                  }}
                                ></div> */}
                                    <img src={item} className="img-demo"></img>
                                  </div>
                                ))}
                            </Slide>
                          </div>
                        </div>
                      ),
                    },
                    {
                      label: "Demo Video",
                      id: "second",
                      content:
                        data.demoVideo.split(".").at(-1) === "gif" ? (
                          <div className="intro-image">
                            <img src={videoUrl} className="img-architect" />
                          </div>
                        ) : (
                          <VideoDemo videoSrc={videoUrl} type={videoType} />
                        ),
                    },
                    {
                      label: "Architecture",
                      id: "third",
                      content: (
                        <div className="intro-image">
                          <img src={archUrl} className="img-architect"></img>
                        </div>
                      ),
                    },
                    {
                      label: "Source Code",
                      id: "fourth",
                      content: (
                        <SpaceBetween size="s">
                          {/* <ValueWithLabel label="Link Repos github">
                        <Link href={gitUrl} external={true} variant="primary">
                          Click here
                        </Link>
                      </ValueWithLabel> */}

                          <ValueWithLabel label="Download ZIP file">
                            {data.srcCode ? (
                              <Button
                                ariaLabel="ZIP file"
                                //href={documentUrl}
                                onClick={
                                  Object.keys(userFeedback).length === 0
                                    ? () => setModalVisible(true)
                                    : handleDownLoad
                                }
                                iconName="download"
                                variant="inline-icon"
                              />
                            ) : (
                              "_"
                            )}
                          </ValueWithLabel>
                        </SpaceBetween>
                      ),
                    },
                    {
                      label: "Implementation Guide",
                      id: "fith",
                      // content: <Markdown remarkPlugins={[remarkGfm]}>{markdown}</Markdown>,
                      content: <Markdown>{guide}</Markdown>,
                    },
                    {
                      label: "Extra Resources",
                      id: "sixth",
                      content: (
                        <SpaceBetween size="s">
                          <ValueWithLabel label="Download Slide Demo">
                            {data.slide ? (
                              <Button
                                ariaLabel="Slide Demo"
                                iconName="download"
                                variant="inline-icon"
                                onClick={() =>
                                  handleDownLoad(
                                    slideUrl,
                                    data.slide.split("/")[2]
                                  )
                                }
                              />
                            ) : (
                              "_"
                            )}
                          </ValueWithLabel>
                          <ValueWithLabel label="Download Architecture Diagram">
                            {data.diagram ? (
                              <Button
                                ariaLabel="Diagram demo"
                                iconName="download"
                                variant="inline-icon"
                                onClick={() =>
                                  handleDownLoad(
                                    diagramUrl,
                                    data.diagram.split("/")[2]
                                  )
                                }
                              />
                            ) : (
                              "_"
                            )}
                          </ValueWithLabel>
                        </SpaceBetween>
                      ),
                    },
                    {
                      label: "Feedback",
                      id: "fb",
                      content: (
                        <Feedback
                          userFeedback={userFeedback}
                          setUserFeedback={setUserFeedback}
                        ></Feedback>
                      ),
                    },
                  ]}
                  fitHeight
                />
                <Modal
                  onDismiss={() => setModalVisible(false)}
                  visible={modalVisible}
                  footer={
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          variant="link"
                          onClick={() => setModalVisible(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          disable={btnDisable}
                          loading={btnLoading}
                          onClick={uploadFeedbackAndDownload}
                        >
                          Countinue
                        </Button>
                      </SpaceBetween>
                    </Box>
                  }
                  header="Fill your information"
                >
                  <SpaceBetween size="xs">
                    <FormField label="Your customer name">
                      <Input
                        onChange={({ detail }) => setCustomerName(detail.value)}
                        value={customerName}
                        onKey
                        placeholder="Enter your customer name"
                        spellcheck
                        autoFocus
                      />
                    </FormField>
                    <FormField label="Your project name ">
                      <Input
                        onChange={({ detail }) => setProjectName(detail.value)}
                        value={projectName}
                        placeholder="Enter your project name"
                        spellcheck
                      />
                    </FormField>
                    <FormField label="Feedback about demo ">
                      <Input
                        onChange={({ detail }) => setFeedBack(detail.value)}
                        value={feedback}
                        placeholder="Feel free your feedback"
                        spellcheck
                      />
                    </FormField>
                  </SpaceBetween>
                </Modal>
              </Container>
            </SpaceBetween>
          </SpaceBetween>
        ) : (
          <div className="loading">
            <img
              src={loadingGif}
              alt="loading..."
              className="cert-loading-gif"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default DemoDetail;
