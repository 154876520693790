import { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { signOut } from 'aws-amplify/auth';

export default function SignOut() {
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const handleSignOut = async () => {
            try {
                // Sign the user out using the Amplify Auth module
                await signOut();

                // Redirect the user to the sign-in page or any other desired page
                navigate('/authen');
            } catch (err) {
                console.error('Error signing out:', err);
                setError(err.message || 'An error occurred while signing out.');
            }
        };

        handleSignOut();
    }, []);

    if (error) {
        return (
            <div>
                <p>Error: {error}</p>
            </div>
        );
    }

    // Redirect the user to the sign-in page (or any other desired page)
    return <></>;
}