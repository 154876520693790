import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { signUp, SignUpInput } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';
import './AuthenForm.css';
// import { I18n } from 'aws-amplify/utils';
// import { translations } from '@aws-amplify/ui-react';
import { useTheme } from '@aws-amplify/ui-react';
import { signIn, signOut } from 'aws-amplify/auth';
import {
    Button,
    Heading,
    Image,
    Text,
    View,
} from '@aws-amplify/ui-react';
import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const components = {
    Header() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.xxxl}>
                {/* <Image
                    alt="Amplify logo"
                    src="https://docs.amplify.aws/assets/logo-dark.svg"
                /> */}
            </View>
        );
    },

    Footer() {
        const { tokens } = useTheme();

        return (
            <View textAlign="center" padding={tokens.space.large}>
                <Text color={tokens.colors.neutral[80]}>
                    &copy; All Rights Reserved
                </Text>
            </View>
        );
    },

    SignIn: {
        Header() {
            const { tokens } = useTheme();

            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Sign in to your account
                </Heading>
            );
        },
        Footer() {
            const { toForgotPassword } = useAuthenticator();

            return (
                <View textAlign="center">
                    <Button
                        fontWeight="normal"
                        onClick={toForgotPassword}
                        size="small"
                        variation="link"
                    >
                        Forgot Password
                    </Button>
                </View>
            );
        },
    },

    SignUp: {
        Header() {
            const { tokens } = useTheme();

            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Create a new account
                </Heading>
            );
        },
        Footer() {
            const { toSignIn } = useAuthenticator();

            return (
                <View textAlign="center">
                    <Button
                        fontWeight="normal"
                        onClick={toSignIn}
                        size="small"
                        variation="link"
                    >
                        Back to Sign In
                    </Button>
                </View>
            );
        },
    },
    ConfirmSignUp: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            return <Text>Footer Information</Text>;
        },
    },
    SetupTotp: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            return <Text>Footer Information</Text>;
        },
    },
    ConfirmSignIn: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            return <Text>Footer Information</Text>;
        },
    },
    ForgotPassword: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.l}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
            );
        },
    },
    ConfirmForgotPassword: {
        Header() {
            const { tokens } = useTheme();
            return (
                <Heading
                    padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                    level={3}
                >
                    Enter Information:
                </Heading>
            );
        },
        Footer() {
            return <Text>Footer Information</Text>;
        },
    },
};
// const startSignOut = async () => {
//     try {
//         await signOut({ global: true });
//         console.log("log out");
//     } catch (error) {
//         console.log("error signing out: ", error);
//     }
// }

// const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));


export default function AuthenForm(props) {

    const [theme, setTheme] = useState();
    const [loading, setLoading] = useState();
    const { tokens } = useTheme();
    const location = useLocation();
    console.log(location);
    const services = {
        async handleSignUp(input: SignUpInput) {
            // custom username and email
            const { username, password, options } = input;
            const customUsername = username.toLowerCase();
            const customEmail = options?.userAttributes?.email.toLowerCase();
            return signUp({
                username: customUsername,
                password,
                options: {
                    ...input.options,
                    userAttributes: {
                        ...input.options?.userAttributes,
                        email: customEmail,
                        "custom:role": "user"
                    },
                },
            });
        },

    };

    return (
        <Authenticator services={services} components={components}
            initialState={location.pathname.action ? location.pathname.action : "SignIn"}
        >
            <Navigate
              to={location.state?.path ? location.state.path : "/"}
              replace={true}
            />
        </Authenticator >
    );
};
